import React, { Component } from "react"
import { Link } from "gatsby"
import MediaQuery from 'react-responsive'
import PressHeader from "../components/pressHeader"
import SEO from "../components/seo"

export default class Press extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_overlay: false
    };
  }

  render() {
    return (
      <div>
        <SEO title="Press" />
        <MediaQuery maxDeviceWidth={1224}>
          <div>
            <div style={{ position: 'absolute',width:"100vw" }}>
              <div class="mobile-headerContainer">
              <a href="#" onClick={(event) => this.setState({ show_overlay: true })}>
                  <div class="mobile-headerOverlayButtonPlaceholder">
                  <img alt="menu-button" src={require('../images/menu_icon.svg')} class="mobile-headerButton"/>
                  </div>
                  </a>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <text class="mobile-headerText">
                    <text style={{ color: "#FF4747" }}>Students</text>
                    <text style={{ color: "#000000" }}> For </text>
                    <text style={{ color: "#5793FA" }}>2020</text>
                  </text>
                </Link>
                <Link to="/map/" style={{ textDecoration: 'none' }}>
                  <div class="mobile-headerVoteButton">
                    <text class="mobile-headerVoteButtonTexts">Vote</text>
                  </div>
                </Link>
              </div>

              <div class="mobile-press-bodyContainer" style={{ backgroundImage: "url('https://images.pexels.com/photos/2883160/pexels-photo-2883160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260')" }}> {/* https://www.pexels.com/license/ pexel license */}
                <div class="mobile-press-darkenBackground">
                  <text class="mobile-press-titleText">Our Team In The Press</text>
                  <a href="#">
                    <text class="mobile-press-subtitleText">Download our media kit</text>
                  </a>
                  <Link to="/about/" style={{ textDecoration: 'none' }}>
                    <div class="mobile-press-button">
                      <text class="mobile-press-buttonText">Contact Us</text>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="mobile-footerContainer">
                <text class="mobile-footerText">
                  <text style={{ color: "#FF4747" }}>Students</text>
                  <text style={{ color: "#ffffff" }}> For </text>
                  <text style={{ color: "#5793FA" }}>2020</text>
                </text>
                <text class="mobile-footerSmallText">2020 © All Rights Reserved</text>
              </div>
            </div>

            <div class="mobile-overlayContainer" style={{ display: this.state.show_overlay === true ? 'block' : 'none' }}>
              <Link to="/" style={{ textDecoration: 'none', marginTop: '5.97vh', position: "absolute" }}><text class="mobile-overlayText">Home</text> </Link>
              <Link to="/about/" style={{ textDecoration: 'none', marginTop: '11.37vh', position: "absolute" }}><text class="mobile-overlayText">Contact Us</text> </Link>
              <Link to="/press/" style={{ textDecoration: 'none', marginTop: '16.77vh', position: "absolute" }}><text class="mobile-overlayText">Press</text> </Link>
              <Link to="/volunteer/" style={{ textDecoration: 'none', marginTop: '22.17vh', position: "absolute" }}><text class="mobile-overlayText">Volunteer</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none', marginTop: '27.57vh', position: "absolute" }}><text class="mobile-overlayText">Where To Vote</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none' }}>
                <div class="mobile-overlayButton">
                  <text class="mobile-overlayButtonText">Vote</text>
                </div>
              </Link>
            </div>
            <div class="mobile-overlayDarken" onClick={(event) => this.setState({ show_overlay: false })} style={{ display: this.state.show_overlay === true ? 'block' : 'none' }} />
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224} maxDeviceHeight={600}>
          <div>
            <div style={{ position: 'absolute' }}>
              <div class="mobile-headerContainer">
              <a href="#" onClick={(event) => this.setState({ show_overlay: true })}>
                  <div class="mobile-headerOverlayButtonPlaceholder">
                  <img alt="menu-button" src={require('../images/menu_icon.svg')} class="mobile-headerButton"/>
                  </div>
                  </a>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <text class="mobile-headerText">
                    <text style={{ color: "#FF4747" }}>Students</text>
                    <text style={{ color: "#000000" }}> For </text>
                    <text style={{ color: "#5793FA" }}>2020</text>
                  </text>
                </Link>
                <Link to="/map/" style={{ textDecoration: 'none' }}>
                  <div class="mobile-headerVoteButton">
                    <text class="mobile-headerVoteButtonTexts">Vote</text>
                  </div>
                </Link>
              </div>

              <div class="mobile-press-bodyContainer" style={{ backgroundImage: "url('https://images.pexels.com/photos/2883160/pexels-photo-2883160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260')" }}> {/* https://www.pexels.com/license/ pexel license */}
                <div class="mobile-press-darkenBackground">
                  <text class="mobile-press-titleText" style={{fontSize:"1.8rem"}}>Our Team In The Press</text>
                 
                  <a href="#">
                    <text style={{marginTop:"20vh"}} class="mobile-press-subtitleText">Download our media kit</text>
                  </a>
                 
                  <Link to="/about/" style={{ textDecoration: 'none' }}>
                    <div class="mobile-press-button" style={{marginTop:"25vh"}}>
                      <text class="mobile-press-buttonText">Contact Us</text>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="mobile-footerContainer">
                <text class="mobile-footerText">
                  <text style={{ color: "#FF4747" }}>Students</text>
                  <text style={{ color: "#ffffff" }}> For </text>
                  <text style={{ color: "#5793FA" }}>2020</text>
                </text>
                <text class="mobile-footerSmallText">2020 © All Rights Reserved</text>
              </div>
            </div>

            <div class="mobile-overlayContainer" style={{ display: this.state.show_overlay === true ? 'block' : 'none' }}>
              <Link to="/" style={{ textDecoration: 'none', marginTop: '5.97vh', position: "absolute" }}><text class="mobile-overlayText">Home</text> </Link>
              <Link to="/about/" style={{ textDecoration: 'none', marginTop: '11.37vh', position: "absolute" }}><text class="mobile-overlayText">Contact Us</text> </Link>
              <Link to="/press/" style={{ textDecoration: 'none', marginTop: '16.77vh', position: "absolute" }}><text class="mobile-overlayText">Press</text> </Link>
              <Link to="/volunteer/" style={{ textDecoration: 'none', marginTop: '22.17vh', position: "absolute" }}><text class="mobile-overlayText">Volunteer</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none', marginTop: '27.57vh', position: "absolute" }}><text class="mobile-overlayText">Where To Vote</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none' }}>
                <div class="mobile-overlayButton">
                  <text class="mobile-overlayButtonText">Vote</text>
                </div>
              </Link>
            </div>
            <div class="mobile-overlayDarken" onClick={(event) => this.setState({ show_overlay: false })} style={{ display: this.state.show_overlay === true ? 'block' : 'none' }} />
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1224}>
        <div>
        <div class="topBar">
          <div class = "logoDiv">
          <Link to="/" style={{ textDecoration: 'none'}}>
            <text class="students-for-2020">
              <text style={{ color: "#FF4747",}}>Students</text>
              <text style={{ color: "#000000" }}> For </text>
              <text style={{ color: "#5793FA" }}>2020</text>
            </text>
          </Link>
          </div>
        <div class = "headerDiv">
          
            <Link to="/press/" style={{ textDecoration: 'none'}}>
              <text class="headerText">Press</text>
            </Link>
          
            <Link to="/volunteer/"  style={{ textDecoration: 'none'}}>
              <text class = "headerText">Volunteer</text>
            </Link>
            <Link to="/about/" style={{ textDecoration: 'none'}}>
              <text class = "headerText">Contact Us</text>
            </Link>

          </div>
          <div class = "headButtDiv">
          <a class="headerButton" href="/map" style={{ textDecoration: 'none'}}>
            <text class="headerButtonText">Where Should I Vote?</text>
          </a>
          </div>
        </div>

        
          <PressHeader>
          <div class="press-HeaderImage">
            <div class = "pressDiv">
            <text class="press-TitleText">Our Team in the Press</text>
            <a href="#" style={{ textDecoration: 'none'}}>
              <text class="press-SubtitleText">Download our media kit</text>
            </a>
            </div>
            </div>
          </PressHeader>
        

          <div class="bottomBar" style={{marginTop:"42vh"}}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <text class="students-for-2020">
                  <text style={{ color: "#FF4747" }}>Students</text>
                  <text style={{ color: "#ffffff" }}> For </text>
                  <text style={{ color: "#5793FA" }}>2020</text>
                </text>
              </Link>
              <div class="rightsReservedDiv">
                <text class="rightsReserved">2020 © All Rights Reserved</text>
              </div>
            </div>
      </div>
        </MediaQuery>
      </div>
    );
  }
}
